import { mapState } from "vuex";
import { clone } from "lodash";

export default {
    data() {
        return {
          annualGrossPay: {
            isDisabled: true,
            isEdit: true,
          },
          isLoading: false,
          openReviseModal: false,
          isPaybandWarning: false,
          inheritGeneralPensionSetting: false,
          inheritGeneralPayeSetting: false,
          inheritGeneralNHFSetting: false,
          openSettingsModal: false,
          disableGross: false,
          payBandError: false,
          payrollStatus: null,
          grossEarningsData: [],
          totalGrossAmount: 0,
          totalPercent: 0,
          variableData: {},
          hasAnnualGross: false,
          hasPayee: null,
          hasPension: null,
          hasNhf: null,
          payFrequency: null,
          payScheduleId: null,
          payType: "salaried",
          oldFrequency: [],
          payGrade: null,
          taxRate: null,
          ratePerHour: null,
          hoursPerDay: null,
          weeksPerYear: null,
            estimatedAnnual: null,
            bankEdit: false,
            taxEdit: false,
            pensionEdit: false,
            disableNumber: false,
            loadingAccount: false,
            bankError: "",
            states: [],
            numberRule: [
              "required",
              {
                name: "number",
                rule: v => this.validateNumber(v)
              }
            ],
            accountInformation: {
              accountNo: null,
              bank: null,
              accountName: null,
              bvn: null,
              irs: null,
              rsaId: null,
              pfaId: null,
              employeeTaxId: null
            },
          compensationData: {
            compaRatio: null,
            grossPay: null,
            payGradeId: "",
            rangeMaximum: 0,
            rangeMidPoint: 0,
            rangeMinimum: 0,
            rangePenetration: 0,
          },
          salaried: [],
          payFrequencies: [
            {
              slug: "monthly",
              name: "Monthly",
            },
            {
              slug: "twice_monthly",
              name: "Twice Monthly",
            },
            {
              slug: "weekly",
              name: "Four Times Monthly",
            },
          ],
          compensation: {
            enabledPAYE: false,
            enabledPension: false,
            enabledNHF: false,
          },
          error: false,
          setSalary: "",
          annualGross: "",
          statusOptions: [
            { name: "Active", radioName: "payRollStatus", value: "active" },
            { name: "Inactive", radioName: "payRollStatus", value: "inactive" },
          ],
        };
      },
      computed: {
        ...mapState({
          // employeeDetails: (state) => state.employee.employeePaymentInfo,
          employeeDetails: (state) => state.employee.allEmployeeDetails,
          pencom: state =>
          state.pension.PFAOrgList.map(pension => ({
              id: pension.pfaId,
              name: pension.pfaName
          })),
          banks: state =>
          state.Banks.map(bank => ({
              ...bank,
              id: bank.code
          }))
        }),
        totalRate() {
          const result = 7;
          return result;
          },
        totalAmount() {
          const result = "---";
          return result;
        },
        handleShowPayRange() {
          if (
            this.$store.state.subscriptionStatus === "trial" &&
            this.payType === "salaried"
          )
            return true;
          if (
            this.$store.state.subscription &&
            this.$store.state.subscription.plan !== "basic" &&
            this.payType === "salaried"
          )
            return true;
          return false
        },
      },
      watch: {
        annualGross(value) {
          let newValue = 0;

          if (value) {
            if (typeof value === "number") {
              newValue = value;
            } else {
              newValue = Number(value.split(",").join(""));
            }
            newValue = newValue.toString();
          }
          return Number(newValue);
        },
      },
    methods: {
        saveAnnualGross() {
            return this.$_editEmployeeByType({
              id: this.$route.params.id,
              type: "grosspay-info",
              payload: {
                grossPay:
                  typeof this.annualGross === "string"
                    ? Number(this.annualGross.split(",").join(""))
                    : Number(this.annualGross),
                payrollStatus: this.payrollStatus,
                enabledPAYE: this.compensation.enabledPAYE,
                enabledPension: this.compensation.enabledPension,
                enabledNHF: this.compensation.enabledNHF,
                payType: this.payType,
                payFrequency: this.payFrequency,
                payScheduleId: this.payScheduleId,
                containsEmploymentInfo: true,
              },
            })
              .catch((err) => {
                this.$toasted.error(`${err}`, {
                  duration: 6000,
                });
              });
          },
        validateNumber(value) {
            this.bankError = "";
            this.accountInformation.accountName = null;
            if (value.toString().length === 10) {
              this.disableNumber = true;
              if (!this.accountInformation.accountName) {
                this.loadingAccount = true;
              }
              this.handleAccountChange(value);
              return true;
            }
            return "Account number must be 10 numbers";
          },
          handleAccountChange(value) {
            this.$_checkAccountNo({
              bankCode: this.accountInformation.bank,
              acctNumber: value
            })
              .then(result => {
                if (result.status === 200) {
                  this.bankError = "";
                  this.loadingAccount = false;
                  this.disableNumber = false;
                  this.accountInformation.accountName = result.data.data.account_name;
                }
              })
              .catch(() => {
                this.loadingAccount = false;
                this.disableNumber = false;
                this.accountInformation.accountName = null;
                this.bankError = "Incorrect Account number";
              });
          },
          async handleClick(type) {
            try {
              await this.$handlePrivilege("employeeDirectory", "editEmployeePayInfo");
              if (type === "bank") {
                this.bankEdit = !this.bankEdit;
              }
              if (type === "tax") {
                this.taxEdit = !this.taxEdit;
              }
              if (type === "pension") {
                this.pensionEdit = !this.pensionEdit;
              }
            } catch (error) {
              this.$toasted.error("You do not have permission to perform this task", {
                duration: 5000
              });
            }
          },
        async handleReviseSalary() {
          try {
            await this.$handlePrivilege(
              "employeeDirectory",
              "reviseEmployeeSalary"
            );
            this.$refs.revise_salary.toggle(this.employeeDetails);
          } catch (error) {
            this.$toasted.error("You do not have permission to perform this task", {
              duration: 5000,
            });
          }
        },
        handleModalClose() {
          this.$store
            .dispatch("employee/getOneEmployeePaymentInfo", this.$route.params.id)
            .then(() => {
              this.getDetails();
            });
          this.getEmployeeSalary();
          this.openReviseModal = false;
        },
        async getEmployeeSalary() {
          const { data } = await this.$_getOneEmployeePaymentInfo(
             this.$route.params.id
          );
          this.grossEarningsData = data.salaryBreakdown;
          this.totalGrossAmount = data.totalGrossAmount;
          this.totalPercent = data.totalGrossPercent;
          this.variableData = data.variableSalaryBreakdown;
          this.$emit("grossPay");
        },
        async editAnnualGross() {
          try {
            await this.$handlePrivilege("employeeDirectory", "editEmployeePayInfo");
            if (
              this.$store.state.subscription &&
              this.$store.state.subscription.plan !== "basic"
            ) {
              // if (this.employeeDetails.employeePayBand) {
              //   this.disableGross = false;
              //   this.payBandError = false;
              // } else {
              //   this.disableGross = true;
              //   this.payBandError = true;
              // }
            } else {
              this.disableGross = false;
              this.payBandError = false;
            }
            this.annualGrossPay.isDisabled = false;
            this.annualGrossPay.isEdit = false;
            this.annualGross =
              typeof this.annualGross === "string"
                ? Number(this.annualGross.split(",").join(""))
                : this.annualGross;
          } catch (err) {
            this.$toasted.error("You do not have permission to perform this task", {
              duration: 5000,
            });
          }
        },
        async getDetails() {
          const {
            grossPay,
            payrollStatus,
            payType,
            payFrequency,
            enabledPAYE,
            enabledPension,
            enabledNHF,
            compensation,
            inheritGeneralPensionSetting,
            inheritGeneralPayeSetting,
            inheritGeneralNHFSetting,
          } = await this.employeeDetails;
          if (grossPay) {
            this.hasAnnualGross = true;
          } else {
            this.hasAnnualGross = false;
          }
          this.annualGross = grossPay
            ? parseFloat(grossPay.toFixed(2)).toLocaleString()
            : null;
          this.payrollStatus = payrollStatus;
          this.payType = payType || "salaried";
          this.inheritGeneralPensionSetting = inheritGeneralPensionSetting;
          this.compensationData = compensation;
          this.inheritGeneralPayeSetting = inheritGeneralPayeSetting;
          this.inheritGeneralNHFSetting = inheritGeneralNHFSetting;
          this.payFrequency = payFrequency;
          this.compensation = {
            enabledPAYE,
            enabledPension,
            enabledNHF,
          };
        },
        getSlug(name) {
          if (name === "weekly") return "Four Times Monthly";
          if (name === "monthly") return "Monthly";
          if (name === "twice_monthly") return "Twice Monthly";
          return "";
        },
        handleFreq(data) {
          const findSalaried = this.oldFrequency.find((item) => item.id === data);
          if (findSalaried) {
            this.payFrequency = findSalaried.name;
          }
        },
        getFrequecyId(name) {
          const findId = this.oldFrequency.find((item) => item.name === name);
          if (findId) this.payScheduleId = findId.id;
        },
        getPayFrequency(id) {
          const findSalaried = this.oldFrequency.find((item) => item.id === id);
          if (findSalaried) {
            this.getSlug(findSalaried.name);
          }
        },
        getPayFrequencySetup() {
          this.$_getPaySchedulesSetup()
            .then(({ data }) => {
              if (data.salaried.length) {
                this.oldFrequency = data.salaried;
                this.getFrequecyId(this.payFrequency);
                this.salaried = data.salaried.map((item) => ({
                  name: this.getSlug(item.name),
                  id: item.id,
                }));
              }
            })
            .catch(() => {
              this.$toasted.error(
                "An Error occured, please contact your administrator",
                { duration: 5000 }
              );
            });
        },
        saveSettings() {
          const payload = {
            inheritGeneralPensionSetting: this.inheritGeneralPensionSetting,
            inheritGeneralPayeSetting: this.inheritGeneralPayeSetting,
            inheritGeneralNHFSetting: this.inheritGeneralNHFSetting,
          };
          this.$_saveSettingPreference(this.$route.params.id, payload)
            .then(() => {
              this.openSettingsModal = false;
              this.handleModalClose();
              this.$toasted.success("Settings saved successfully", {
                duration: 5000,
              });
            })
            .catch(() => {
              this.openSettingsModal = false;
              this.$toasted.error(
                "Settings not saved please contact your administrator",
                { duration: 5000 }
              );
            });
        },
        saveBankInfo() {
            this.$_saveEmployeeBankInfo({
              userId: this.$route.params.id,
              data: {
                bank: this.accountInformation.bank,
                accountNo: this.accountInformation.accountNo,
                bvn: this.accountInformation.bvn
              }
            })
          },
          saveTaxInfo() {
            this.$_saveEmployeeTaxInfo({
              userId: this.$route.params.id,
              data: {
                sirs: this.accountInformation.sirs,
                employeeTaxId: this.accountInformation.employeeTaxId
              }
            })
          },
          savePensionInfo() {
            this.$_saveEmployeePensionInfo({
              userId: this.$route.params.id,
              data: {
                pfaId: this.accountInformation.pfaId,
                rsaId: this.accountInformation.rsaId
              }
            })
          }
      },
      async mounted() {
          await this.$store.dispatch(
            "employee/getOneEmployeeAll",
            this.$route.params.id
          );
          
        await this.getDetails();
        await this.getEmployeeSalary();
        await this.getPayFrequencySetup();
    
        await this.$store.dispatch("getBanks");

        this.$_getTaxAuthority().then(item => {
          this.states = item.data.taxAuthorities;
        });
        await this.$store.dispatch("pension/getPFAOrgList");
        this.accountInformation = await clone(this.employeeDetails);
      }
}