export default {
  data() {
    return {
      designationsArray: [],
      globalFunctionId: null,
      globalDesignation: null,
      globalReportingTo: null
    }
  },
  methods:{
    async getSupDesignation() {
      try {
        const response = await this.$_getDesignationsWithPayband('');
        this.designationsArray = response.data.designations.filter(item => item.isSupervisory);
        this.designationsArray.push({ name: "Nil", id: "null" });
      } catch (error) {
        throw new Error(error);
      }
    },
    changeReportingTo(id) {
      this.globalReportingTo = id
    },
  },
  computed:{
    setFunctions() {
      return this.$store.state.org.getAllFunctions;
    },
    setDesignation() {
      const customiseDesignation = this.$store.state.org.getAllFunctions.find(
        (item) => item.id === this.globalFunctionId
      );
      if (customiseDesignation) {
        customiseDesignation.designations.forEach((element, index )=> {
          if (element.isSupervisory && element.hasUser) {
            customiseDesignation.designations[index] = { ...element, disabled: true }
          }
          else {
            customiseDesignation.designations[index] = { ...element, disabled: false }
          }
        });
      }
      return customiseDesignation
    },
    setLevel() {
      let result;
      if (this.setDesignation) {
        result = this.setDesignation.designations.find(
          (item) => item.id === this.globalDesignation
        );
      }
      return result ? result.level : null;
    },
    setReportingTo() {
      let findReportingTo = null; let result
      if (this.setDesignation) {
        result = this.setDesignation.designations.find(
          (item) => item.id === this.globalDesignation
        );
        if (result) {
          this.setFunctions.forEach(element => {
            const foundDesignation = element.designations.find(item => item.id === result.reportingTo);
            if (foundDesignation) {
              findReportingTo = foundDesignation;
            }
          });
          if (findReportingTo?.hasUser) {
            const { id } = findReportingTo.hasUser
            this.changeReportingTo(id)
          }
          else {
            this.changeReportingTo(null)
          }

        }
      }
      return findReportingTo ? findReportingTo.hasUser : null
    }
  },
  async mounted() {
    await this.$store.dispatch("org/getFunction");
    await this.getSupDesignation()
  }
}
